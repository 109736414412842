import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
// var nodemailer = require('nodemailer');

// import {NgForm} from '@angular/forms';

declare function initFunc(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public gs: GeneralService) { }
  errors = { err: false, msg: "" }
  ngOnInit(): void {
    initFunc();
  }
  onSubmit(f) {
    f.value['date'] = (<HTMLInputElement>document.getElementById('appointment_date')).value;
    f.value['time'] = (<HTMLInputElement>document.getElementById('appointment_time')).value;
    console.log(f.value);
    if (f.value.fname == undefined || f.value.fname == "") {
      this.errors.err = true
      this.errors.msg = "First Name required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.lname == undefined || f.value.lname == "") {
      this.errors.err = true
      this.errors.msg = "Last Name required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.service == undefined || f.value.service == "") {
      this.errors.err = true
      this.errors.msg = "Service required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    
    if (f.value.phone == undefined || f.value.phone == "") {
      this.errors.err = true
      this.errors.msg = "Phone Number required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.date == undefined || f.value.date == "") {
      this.errors.err = true
      this.errors.msg = "Date required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.time == undefined || f.value.time == "") {
      this.errors.err = true
      this.errors.msg = "Time required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }

    this.gs.sendEmail("appointment", f.value);
  }
}
