<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/img10.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4">Hassle free RCM<span>Medicomp Solutions</span></h1>
                    <h3 class="subheading">End-to-end solutions for an unparalleled experience</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a>
                        <a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Request More Info</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Medicomp Solutions</span>
                <h2 class="mb-4">Hassle free RCM through Medicomp Solutions</h2>
                <p>End-to-end solutions for an unparalleled experience</p>
            </div>
        </div>
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills d-flex text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="false" style="width: 33.33%;">Revenue Management</a>
                        <a class="nav-link ftco-animate fadeInUp ftco-animated show" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" style="width: 33.33%;">End-to-End RCM</a>
                        <a class="nav-link ftco-animate fadeInUp ftco-animated show" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false" style="width: 33.33%;">Customer Satisfaction</a>
                        
                    </div>
                </div>
                <div class="col-md-12 tab-wrap">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade active show" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" >
                                        <img src="assets/images/img/revenue-cycle-management.png" style="width: 100%;
                                        height: 265px;" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>Complete Revenue Cycle Management</h2>
                                    <p>Healthcare revenue cycle is riddled with complexities due to an inherent dynamic, multiple payers and regulations. Manage the revenue flow into your organization using our advanced billing systems.</p>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Improve Efficiency</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Multifunctional capabilities</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Payment reminders and tracking of pending payments.</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Reduce the operational costs by outsourcing your billing systems.</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Precise error-free coding using searchable International Classification of Diseases database.</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>omprehensive and customizable financial report generation with applied analytics of key indicators.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-day-2-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/about26.png);"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>End-to-End RCM</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="mt-4">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Front-end processes</th>
                                                    <th>Insurance eligibility verification </th>
                                                    <th>Billing</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>Transparency and clear communication to ensure customer satisfaction</p>
                                                        <ul>
                                                            <li>Scheduling</li>
                                                            <li>Admissions</li>
                                                            <li>Preauthorization</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <p>Real-time tracking of documentation and claim status</p>
                                                        <ul>
                                                            <li>Documentation</li>
                                                            <li>Coding and costing</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <p>Error free costing and coding</p>
                                                        <ul>
                                                            <li>Denial management</li>
                                                            <li>Payment receipt</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-day-3-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/img/img7.jpg);"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>Avoid these potential areas of conflict and ensure customer satisfaction</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Coding</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Billing delays</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Documentation</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Patient complaints</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Necessity and sequence of care</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Backlog of patient correspondence</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>