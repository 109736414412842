<section class="home-slider owl-carousel">

    <div class="slider-item" style="background-image:url(assets/images/online_appointment1.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">AppointMaster 
                        <!-- <span>Real-Time Online Appointment Scheduling</span> -->
                    </h1>
                    <p class="subheading"><b>Real-Time Online Appointment Scheduling</b></p>
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            CGM Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/online_appointment2.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">AppointMaster 
                        <!-- <span>Real-Time Online Appointment Scheduling</span> -->
                    </h1>
                    <p class="subheading"><b>Real-Time Online Appointment Scheduling</b></p>
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                        CGM Medisoft</a>
                </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/online_appointment3.png);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">AppointMaster 
                        <!-- <span>Real-Time Online Appointment Scheduling</span> -->
                    </h1>
                    <p class="subheading"><b>Real-Time Online Appointment Scheduling</b></p>
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                    CGM Medisoft</a>
            </p> -->
                </div>
            </div>
        </div>
    </div>


</section>
<section class="pt-2 pb-2">
    <div class="container">
        <div class="row">
            <div class="col-md-12 tab-wrap">
                <div class="tab-content bg-light p-8 p-md-5 ftco-animate" id="v-pills-tabContent">
                    <!-- <h4>AppointMaster</h4> -->
                    <!-- <img src="assets/images/appointmaster.jpg" style="width: 200px; height: 150px;"> -->
                    <div class="row">
                        <div class="col-md-8">
                            <h4>Real-Time Online Appointment Scheduling</h4>
                            <!-- <p><strong>Real-Time Online Appointment Scheduling</strong></p> -->
                            <p>AppointMaster allows patients to request appointments entirely online exactly the way you
                                book airline tickets today, <strong> directly from the practice web site:</strong></p>

                            <ul class="list-unstyled">
                                <li><span class="ion-ios-arrow-round-forward mr-2"
                                        style="color:#2b8dcf;"></span>Available schedule real-time synchronized from
                                        CGM Medisoft Office Hours</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Patient
                                    never sees the practice scheduled, only “openings” matching practice rules.</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2"
                                        style="color:#2b8dcf;"></span>Practice is notified instantly</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Speeds
                                    Practice reviews and confirms request online</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Works
                                    seamlessly with your current management software</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Tracks
                                    No phone calls</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2"
                                        style="color:#2b8dcf;"></span>Available after business hours</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2"
                                        style="color:#2b8dcf;"></span>Accurate, easy and quick</li>
                                <li><span class="ion-ios-arrow-round-forward mr-2"
                                        style="color:#2b8dcf;"></span>Accessible from any online device (Android, PC,
                                    PDA, iPhone, Blackberry…)</li>
                            </ul>
                        </div>
                        <div class="col-md-4"><a target="_blank" href="assets/images/patient.jpg"><img
                                    src="assets/images/patient.jpg" style="width: 100%;"></a></div>

                    </div>
                    <hr>
                    <h4>Automatic Reminders</h4>
                    <p>AppointMaster automatically sends appointment reminders to all your scheduled patients in Office
                        Hours, using emails, text-messagesor human speech phone calls.</p>
                    <p>The patient can then easily confirm their appointment by email, cellular phone, PDA or any mobile
                        device.</p>
                    <ul class="list-unstyled">
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Fast, efficient
                        </li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Dramatically
                            reduces “no shows”</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Provides total
                            control over when notices go out</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Speeds Complete
                            customization of the messages content and spoken voices</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Speeds
                            Multi-languages support for your Spanish speaking patients</li>
                    </ul>
                    <hr>
                    <h4>24/7 Mobile Access</h4>
                    <p>AppointMaster Mobile allows the Doctors and staff to stay in touch with the heart of the practice
                        24/7, from any mobile phone.</p>

                    <ul class="list-unstyled">
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Check entire
                            schedule</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Place a call to
                            any patient at anytime</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Just as easily
                            send them email or text</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Speeds Get
                            notified and reply quickly to patient’s requests coming from the web</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Send “Emergency
                            Closing” notices to all your scheduled patients automatically via emails, text or phones.
                        </li>
                    </ul>
                    <p>This is the one ideal tool for busy providers commuting from one office to another and for the
                        staff to stay in touch constantly with their AppointMaster online patient requests.</p>

                    <hr>
                    <h4>Online Confirmation</h4>
                    <p>Each appointment automatically reminded by AppointMaster can be confirmed instantly with a simple
                        mouse click or one phone keystroke.</p>

                    <ul class="list-unstyled">
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>It’s easy</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>It’s fast</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Reduces
                            dramatically “No Shows”</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Saves a lot of
                            time and energy</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Improves the
                            image of your practice</li>
                    </ul>
                    <p>Every day AppointMaster sends to the practice a report of confirmed and unconfirmed appointments
                        for the coming days.</p>
                    <p>The staff is automatically informed and can easily keep track.</p>
                    <p>The number of actual phone calls is dramatically reduced while the patient satisfaction
                        increases.</p>

                    <hr>
                    <h4>Patient Surveys</h4>
                    <p>AppointMaster allows the practice to monitor patient’s satisfaction automatically.</p>
                    <p>AEvery day, we invite patients who received care in the practice to complete a customized
                        satisfaction survey form. Results can be checked easily, at any time.</p>

                    <hr>
                    <h4>Patient Communication</h4>
                    <p>AppointMaster comes with many additional services to help the practice communicate efficiently
                        and easily with their patients:</p>
                    <p>Patient re-activation<br>
                        Patient’s birthday<br>On demand custom campaigns</p>


                </div>

            </div>
        </div>

    </div>
</section>