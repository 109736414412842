import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc():any;

@Component({
  selector: 'app-cleanical-ehr',
  templateUrl: './cleanical-ehr.component.html',
  styleUrls: ['./cleanical-ehr.component.scss']
})
export class CleanicalEhrComponent implements OnInit {

  constructor(public gs:GeneralService) { }

  ngOnInit(): void {
    initFunc();
  }

}
