<hr class="logo_hr">

<nav class="navbar py-4 navbar-expand-lg ftco_navbar navbar-light bg-light flex-row">
    <div class="container">
        <img [routerLink]="['/']" src="assets/images/logo.png" alt="" class="logo_heartline">
        <img [routerLink]="['/']" class="logo_mobileview d-block d-sm-none" src="assets/images/logo.png" alt="">
        <div class="row no-gutters d-flex align-items-start align-items-center px-3 px-md-0" style="width: 100%;">
            <div class="col-lg-2 pr-4 align-items-center">
                <a class="navbar-brand" href="javascript:void(0);" [routerLink]="['/']">
                    <!-- <span>Medi</span>comp -->
                </a>
            </div>
            <div class="col-lg-10 d-none d-md-block">
                <div class="row d-flex">
                    <!-- <div class="col-md-4 pr-4 d-flex topper align-items-center">
                        <div class="icon bg-white mr-2 d-flex justify-content-center align-items-center"><span class="icon-map"></span></div>
                        <span class="text">Address: 2750 Prosperity Ave. Suite #110
                        Fairfax, VA 22031	</span>
                    </div> -->
                    <div class="col-md pr-4 d-flex topper align-items-center">
                        <div class="icon bg-white mr-2 d-flex justify-content-center align-items-center"><span
                                class="icon-paper-plane"></span></div>
                        <span class="text" style="width: 100%;">Email: {{gs.dataSet.email}}</span>
                    </div>
                    <div class="col-md-5 pr-4 d-flex topper align-items-center">
                        <div class="icon bg-white mr-2 d-flex justify-content-center align-items-center">
                            <a href="tel:{{gs.dataSet.phoneNo}}">
                                <span class="icon-phone2"></span></a></div>
                        <span class="text">Phone: {{gs.dataSet.phoneNo}}</span>
                    </div>
                    <!-- Old one -->
                    <!-- <div class="col-md-3 pr-4 d-flex topper align-items-center">
                        <a href="https://www.medicomp.org/wp-content/uploads/2013/07/MedicompOnlineSupport.exe">
                            <img src="assets/images/medicompsupportbutton.png"
                                style="margin-top: -12px;margin-left: -35px;" width="234" height="63" align="center"
                                alt="Medicomp Online Support">
                        </a>
                        <div class="dropdown">
                            <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
                                    src="assets/images/medicompsupportbutton.png" alt="Medicomp Online Support"
                                    style="margin-top: -12px;margin-left: -35px;" width="234" height="63"
                                    align="center">
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="assets/files/TeamViewerQS.zip">Download for MAC</a>
                                <a class="dropdown-item" href="assets/files/MedicompOnlineSupport.exe">Download for
                                    Windows</a>
                            </div>
                        </div>
                    </div> -->
                   
                   <!-- Latest -->
                    <div class="col-md-3 pr-4 d-flex topper align-items-center">
						<a href="{{ link }}" onclick="iwasClicked()">
							<img src="assets/images/medicompsupportbutton.png" width="234" height="63" align="center" alt="Medicomp Online Support" style="margin-top: -12px; margin-left: -35px" />
						</a>
					</div>
                </div>
            </div>
        </div>
    </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container d-flex align-items-center">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
            aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="oi oi-menu"></span> Menu
        </button>
        <p class="button-custom order-lg-last mb-0"><a href="javascript:void(0);" [routerLink]="['/appointment']"
                class="btn btn-secondary py-2 px-3">Make
                An Appointment</a></p>
        <div class="collapse navbar-collapse" id="ftco-nav" data-hover="dropdown"
            data-animations="fadeInDown fadeInRight fadeInUp fadeInLeft">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active"><a href="javascript:void(0);" [routerLink]="['/']"
                        class="nav-link pl-0">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Solutions
                    </a>

                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/elecronic-health-record']">Electronic Health Record</a>
                        <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/Practice-management']">Pratice management</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/medical-billing']">Medical
                            Billing</a>
                        <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/revenue-cycle-management']">Revenue Cycle Management</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Product
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <!-- <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/cleanical-ehr']">Medisoft
                            Clinical EHR
                        </a> -->
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/prac-management']">Medisoft
                            Practice Management</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/clime-statement']">Claims &
                            Statements</a>
                        <!-- <a class="dropdown-item" href="javascript:void(0);">Add-Ons</a> -->
                        <div class="dropdown-item" id="submenu_toggle"><a href="javascript:void(0);">Add-Ons </a>
                            <!-- <span class="caret"></span> -->
                            <div class="dropdown-submenu submenu">
                                <a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="['/mobile-patient-inkete']">Medisoft Mobile with Patient Intake</a>
                                <!-- <a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="['/dragon-medical']">Dragon Medical
                                </a> -->
                                <a class="dropdown-item" [routerLink]="['/appoint-master']">AppointMaster</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/cunsulting']">Consulting</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/training']">Training</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/billing-sol']">Billing
                            Solutions</a>
                        <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/hardawer-networking']">Hardware / Networking</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/remote-online']">Remote
                            Online Backup</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/custom-report']">Custom
                            Report Design</a>
                    </div>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Support
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/support-sol']">Our Support
                            Solutions</a>
                        <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/download-link']">Medisoft
                            {{gs.dataSet.version}} Download Link</a>
                        <!-- <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/encoder-pro']">Medisoft
                            Encoder Pro
                        </a> -->
                        <!-- <a class="dropdown-item" href="javascript:void(0);"
                            [routerLink]="['/v19download-link']">Medisoft V19 Download
                        </a> -->
                    </div>
                </li>
                <li class="nav-item"><a href="javascript:void(0);" [routerLink]="['/contactus']"
                        class="nav-link">Contact Us</a></li>
            </ul>
        </div>
    </div>
</nav>