import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc():any;

@Component({
  selector: 'app-mobile-patient-inkete',
  templateUrl: './mobile-patient-inkete.component.html',
  styleUrls: ['./mobile-patient-inkete.component.scss']
})
export class MobilePatientInketeComponent implements OnInit {

  constructor(public gs:GeneralService) { }

  ngOnInit(): void {
    initFunc();
  }

}
