<section class="ftco-intro" style="background-image: url(&quot;images/bg_3.jpg&quot;); background-position: 50% -10px;"
    data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>We Provide Free Consultation</h2>
                <p class="mb-0">Your practice is our top priority. Please get in touch with us.</p>
                <p class="mt-2 text-justify">MediComp is the Washington DC Metropolitan area’s leading solution provider of Practice Management
                    (PM) and Electronic Health Records (EHR) software. For 29 years Medicomp has been dedicated to
                    providing complete and customized automated healthcare solutions to healthcare practices. Our
                    solutions are complete and customized to the specific needs of a practice with the ultimate goal of
                    increasing workflow efficiency and improving quality-of-care, all at a great value. We offer the
                    complete solution — software, hardware, networking, training, support, appointment reminders, remote
                    back-up service and a proven and established billing department.</p>
                <p class="mb-0"><a href="javascript:void(0)" [routerLink]="['/appiontment']"
                        class="btn btn-secondary px-4 py-3">Free Consutation</a></p>
            </div>
            <!-- <div class="col-md-3 d-flex align-items-center">
                <p class="mb-0"><a href="javascript:void(0)" [routerLink]="['/cunsulting']"
                        class="btn btn-secondary px-4 py-3">Free Consutation</a></p>
            </div> -->
        </div>
    </div>
</section>
<section class="ftco-section testimony-section bg-light">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate">
                <!-- <span class="subheading">Testimonials</span> -->
                <h3 class="mb-4">Testimonials</h3>
                <p>For 29 years Medicomp has been dedicated to providing complete and customized automated healthcare
                    solutions to healthcare practices.</p>
            </div>
        </div>
        <div class="row ftco-animate justify-content-center">
            <div class="col-md-8">
                <div class="carousel-testimony owl-carousel">
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_1.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p style="text-align: justify;">“Our practice signed on with Medicomp for our billing
                                    and IT support at the end of 2011 when our practice some growing pains. Their
                                    billing and IT staff are always attentive and very responsive with any concerns.
                                    They’ve even furthered our efforts to go paperless. Medicomp has been quintessential
                                    in growing our practice into one of the top Dermatology clinics in the greater DC
                                    area.”</p>
                                <p class="name">Michael R. Armstrong</p>
                                <span class="position">General Manager</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_2.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p style="text-align: justify;">“I started working with Medicomp approximately 7 years
                                    ago. I was using another billing service and had enormous amounts of money from
                                    claims that had been rejected and had not been paid.I was desperate. Medicomp came
                                    to my rescue!They refilled old claims, set me up correctly with Medicare allowing me
                                    to collect.That was 7 years ago and I am still with them today.I am grateful to
                                    them.”</p>
                                <p class="name">Yolanda C. Holmes</p>
                                <span class="position"> M.D.</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_3.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p style="text-align: justify;">“For several years, my practice has depended on
                                    Medicomp’s services and support including a full practice management and EMR
                                    solution. I can’t express enough how impressed we have been with Medicomp’s
                                    professionalism and dedication. Their billing department has saved us substantial
                                    time and money leaving us more time to concentrate on our patients. I highly
                                    recommend Medicomp.”</p>
                                <p class="name">Michael Rezaian</p>
                                <span class="position">M.D.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                <div class="img d-flex align-self-stretch"
                    style="background-image: url(assets/images/cgm-medisoft-300x250-blue.png); background-size: cover; background-position: center; width: 100%; height: 100%;">
                </div>
            </div>
            
            
        </div>
    </div>
</section>