import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-custum-report',
  templateUrl: './custum-report.component.html',
  styleUrls: ['./custum-report.component.scss']
})
export class CustumReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
