import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-billing-sol',
  templateUrl: './billing-sol.component.html',
  styleUrls: ['./billing-sol.component.scss']
})
export class BillingSolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
