<footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="ftco-footer-widget">
                    <h2 class="ftco-heading-2 logo">Medi<span>Comp</span></h2>
                    <p>{{gs.dataSet.footer.title}}</p>
                </div>
                <div class="ftco-footer-widget">
                    <h2 class="ftco-heading-2">Office Address</h2>
                    <div class="block-23">
                        <ul>
                            <li><span class="icon icon-map-marker"></span><span class="text">Medicomp, 2750
                                    Prosperity Ave. Suite #110 Fairfax, VA 22031</span></li>
                            <li><a href="tel:{{gs.dataSet.phoneNo}}"><span class="icon icon-phone"></span><span class="text">{{gs.dataSet.phoneNo}}</span></a></li>
                            <li><a href="tel:{{gs.dataSet.phoneNo}}"><span class="icon icon-phone"></span><span class="text">Fax:
                                {{gs.dataSet.phoneNo}}</span></a></li>
                            <li><a href="mail:{{gs.dataSet.email}}"><span class="icon icon-envelope"></span><span
                                        class="text">{{gs.dataSet.email}}</span></a></li>
                        </ul>
                    </div>

                    <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                        <!-- <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-twitter"></span></a></li> -->
                        <li class="ftco-animate"><a href="https://www.facebook.com/YeatsEHR/" target="_blank"><span class="icon-facebook"></span></a></li>
                        <li class="ftco-animate"><a href="https://www.youtube.com/channel/UC8URVgF9nWKc9rBm6_Wy47w" target="_blank"><span class="icon-youtube"></span></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget ml-md-4">
                    <h2 class="ftco-heading-2">Links</h2>
                    <ul class="list-unstyled">
                        <li><a [routerLink]="['/']"><span class="ion-ios-arrow-round-forward mr-2"></span>Home</a></li>
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>About Us</a></li>
                        <li><a href="download-link"><span class="ion-ios-arrow-round-forward mr-2"></span>Demo Videos</a></li>
                        <!-- <li><a href="download-link"><span class="ion-ios-arrow-round-forward mr-2"></span>Services</a></li>
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Support</a></li> -->
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Contact</a></li>
                    </ul>
                </div>
                <div class="ftco-footer-widget ml-md-4">
                    <h2 class="ftco-heading-2">Services</h2>
                    <ul class="list-unstyled">
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Consulting</a></li>
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Training</a></li>
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Billing Solutions</a>
                        </li>                        
                        <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Remote Online
                                Backup</a></li>
                        <li><a href="encoder-pro"><span
                                    class="ion-ios-arrow-round-forward mr-2"></span>FAQ</a></li>
                       <!--  <li><a href="javascript:void(0);"><span class="ion-ios-arrow-round-forward mr-2"></span>Custom Report
                                Design</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget mb-5">
                    <h2 class="ftco-heading-2">Subscribe Us!</h2>
                    <form action="#" class="subscribe-form">
                        <div class="form-group">
                            <input type="text" class="form-control mb-2 text-center"
                                placeholder="Enter email address">
                            <input type="submit" value="Subscribe" class="form-control submit px-3">
                        </div>
                    </form>
                </div>
                <div class="col-md">
                    <div class="ftco-footer-widget">
                        <h2 class="ftco-heading-2">Office Hours</h2>
                        <h3 class="open-hours pl-4"><span class="ion-ios-time mr-3"></span>Monday To Friday </h3>
                        <h3 class="open-hours pl-4">9:00am to 5:00pm</h3>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-md-12 text-center">
            <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> All rights reserved by Medicomp
                Consulting.
            </p>
        </div>
    </div>
</footer>