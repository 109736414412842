<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/bn10.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4" style="color: #ff8000;">order for your<span>practice to work</span></h1>
                    <h3 class="subheading">MediComp provides a broad range of network related services. </h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-10 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Hardware And Networking</span>
                <h2 class="mb-4">Practice to work at peak performance and efficiency</h2>
                <p>In order for your practice to work at peak performance and efficiency,need the hardware required to supplement your system. MediComp provides a broad range of network related services. MediComp offers practical network solutions that will bring efficiency to your practice.</p>
            </div>
        </div>
        <div class="row text-center align-items-stretch pt-0">
            <div class="col-md-6 col-sm-12">
                <!-- <h1>Hardware</h1> -->
                <div class="service-box">
                    <div class="service-icon" style="background-color: #2f89fc">
                        <div class="front-content">
                            <i class="fa fa-trophy"></i>
                            <h3>Hardware</h3>
                        </div>
                    </div>
                    <div class="service-content">
                        <h3>Hardware</h3>
                        <p style="text-align: justify;font-size: 16px;">In order for your
                            practice to work at peak
                            performance and efficiency, you will need the hardware required to
                            supplement your system.
                            Our consultants will take into account your needs and the size of
                            your practice in order to
                            weigh the requirements for the design of your system. We will
                            provide recommendations as to
                            how your systems should be designed, and the complementing hardware
                            to go with it.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 ">
                <!-- <h1>Networking</h1> -->
                <div class="service-box">
                    <div class="service-icon" style="background-color: #ff8000;">
                        <div class="front-content">
                            <i class="fa fa-anchor"></i>
                            <h3>Networking</h3>
                        </div>
                    </div>
                    <div class="service-content">
                        <h3>Networking</h3>
                        <p style="text-align: justify; font-size: 16px">MediComp provides a
                            broad range of network
                            related services. MediComp offers practical network solutions that
                            will bring efficiency to
                            your practice.Medicomp's exceptional technological capabilities
                            enabled the creation of the
                            world's largest and most advanced Holter network system,
                            interconnecting Kaiser's 27
                            hospitals throughout Northern California.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-8 mt-4">
                <p><strong>We provide the following network services:</strong></p>
                <ul class="list-unstyled">
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Network Planning</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Network Design</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Network Implementation</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Network Optimization</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Network Troubleshooting</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Wireless Networking</li>
                    <p>Please contact us for more information!</p>
                </ul>
            </div>
        </div>
    </div>
</section>