<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/503.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Install<span>Medisoft, Office Hours Version </span></h1>
                    <h3 class="subheading">Medisoft Patient Accounting Version {{gs.dataSet.version}} Install and Medisoft Office Hours Professional Version {{gs.dataSet.version}}  Install </h3>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
     <div class="slider-item" style="background-image:url(assets/images/img/504.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                   <h1 class="mb-4"> Install<span>Medisoft, Office Hours Version </span></h1>
                    <h3 class="subheading">Medisoft Patient Accounting Version {{gs.dataSet.version}}  Install and Medisoft Office Hours Professional Version {{gs.dataSet.version}} Install </h3>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="">
        <div class="col-md-12 pt-4">
            <div class="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated"><span _ngcontent-ebw-c42="" class="subheading">quality technical support</span>
                <h2 class="mb-4">Provide quality technical support for our products.</h2>
            </div>
            <p style="text-align: justify;">We provide quality technical support for all of our products. With over 20
                years of experience we have the knowledge you need to solve any problems that may arise. All support
                will be offered Onsite or Offsite. Our expert technicians can troubleshoot and solve any problems you
                may have.</p>
        </div>
        <div class="col-md-12 col-sm-12 col-12 mb-4">
            <!-- <img src="assets/images/medisoftbanner.gif" class="img-responsive" style="width: 100%;"> -->
            <div class="body">
                <div class="row" style="padding: 10px;">
                    <div class="col-md-8 col-sm-12 col-12 pl-4">
                        <h6 style="padding-bottom: 10px;">Medisoft Version {{gs.dataSet.version}}</h6>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Version {{gs.dataSet.version}}</h6>
                                            <p style="color: #fafafa;">Medisoft Patient Accounting Version {{gs.dataSet.version}} Web
                                                Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>
                                            <a href="http://www.medisoft.com/GetLatest/File/PAWebDownload" class="w3-button w3-green">Download Medisoft V{{gs.dataSet.version}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Office Hours Version {{gs.dataSet.version}}
                                            </h6>
                                            <p style="color: #fafafa;">Medisoft Office Hours Professional Version {{gs.dataSet.version}} Web
                                                Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>
                                            <a href="http://www.medisoft.com/GetLatest/File/OHWebDownload" class="w3-button w3-green">Download Office Hours v{{gs.dataSet.version}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="ftco-section">
    <div class="container">
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills d-flex text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <!-- <a class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="false" style="width: 50%;">Medical Billing</a> -->
                        <h6 class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" style="width: 1000%;">2022 PRESALE CGM MEDISOFT V29, CGM LYTEC 2022, eMEDIX, OTHER SALE ITEMS
                        </h6>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="container">
                            <div class="text-center position-relative">
                                <h5>Presale CGM Medisoft V29 Videos</h5>
                                <!-- <hr class="title-divider"> -->
                            </div>
                            <div class="row text-center align-items-stretch" id="faq1">
                                <div class="row text-center align-items-stretch" id="faq1">
                                   
                                
                                    <!-- New Vimeo videos -->
                                    <div class="my-4 col-md-4">
                                        <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                            <p>New Feature  </p>
                                            <div class="card-body" style="padding: 0.25rem;">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004634375" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="my-4 col-md-4">
                                        <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                            <p>Enhanced Estimates</p>
                                            <div class="card-body" style="padding: 0.25rem;">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004681587" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="my-4 col-md-4">
                                        <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                            <p>Faster MultiLink Transactions</p>
                                            <div class="card-body" style="padding: 0.25rem;">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004684175" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="my-4 col-md-4">
                                        <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                            <p>Integrated Payment Solution</p>
                                            <div class="card-body" style="padding: 0.25rem;">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004684185" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="my-4 col-md-4">
                                        <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                            <p>Transaction Entry Alerts</p>
                                            <div class="card-body" style="padding: 0.25rem;">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004684195" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-12">
                                    <h5>Presale CGM Medisoft V29 PDF</h5>                                    
                                    <div class="row pt-6">     
                                        <div style="padding: 10px;"> 
                                            <button type=submit class="pdf" onclick=" window.open('assets/images/pdf/cgm-medisoft-v29-brochure.pdf'); return true;"><span>Download</span><span>Brochure</span></button>
                                            <button type=submit class="pdf" onclick=" window.open('assets/images/pdf/cgm-medisoft-v29-matrix.pdf'); return true;"><span>Download</span><span>Matrics</span></button>
                                            <button type=submit class="pdf" onclick=" window.open('assets/images/pdf/cgm-medisoft-v29-postcard.pdf'); return true;"><span>Download</span><span>Presale</span></button>
                                        </div>
                                    </div>                                  
                                    
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>