import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

declare function sendMail(a: any, b: any): any;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  dataSet: any = {
    version: 29,
    practice_management: {
      practice: '<p style="text-align: justify;">Medisoft® version 29 — Simplify and streamline the way you run your practice.</p><p style="text-align: justify;">Medisoft is a proven, trusted and affordable practice management solution designed to simplify and streamline the way you run your practice. medisoft V29 is the latest release of a system depended on by tens of thousands of physician practices. Brought to you by McKesson, medisoft V29 is an easy-to-use, fast solution for a modern day practice.</p><p style="text-align: justify;">Medisoft’s new Patient Intake Mobile application for iPad® and Android™ automates the entire information-gathering process – giving you the ability to save valuable time and money. Simply hand your patients the mobile device*, and through the Medisoft Mobile application they can enter their new information and edit already-existing information in Medisoft. The patient’s information will flow directly into Medisoft for you to validate and apply to their account.</p> <p style="text-align: justify;">Medisoft’s Patient Intake Mobile application can significantly reduce the amount of time spent copying patient information from intake forms into your software. With your front office staff focusing on more productive tasks, you have the ability to save thousands of dollars by increasing efficiency.</p> <p style="text-align: justify;">*Mobile device not included with purchase of Medisoft PM or Medisoft PM/EHR.</p>'
    },
    phoneNo: "+1 703-204-1460",
    email: "info@medicomp.org",
    footer: {
      title: "Your practice is our top priority. Please get in touch with us."
    }
  }

  sendEmail(w, data) {
    let msg = "";

    let mail_data = {
      Host: "smtp.medicomp.org",
      Username: "theju@medicomp.org",
      Password: "mRQUtJtrbha8",
      To: 'theju@medicomp.org',
      From: "theju@medicomp.org",
    }

    if (w == "appointment") {
      msg = "<html><head></head><body>" +
        "<h2> Name : " + data.fname + " " + data.lname +
        "</h2><h2> Service : " + data.service +
        "</h2><h2> Phone : " + data.phone +
        "</h2><h2> Date : " + data.date +
        "</h2><h2> Time : " + data.time +
        "</h2><p> Message : " + data.message +
        "</p></body><html>";
      mail_data["Subject"] = "New Appointment Request";
    }
    if (w == "contact") {
      msg = "<html><head></head><body>" +
        "<h2> Name : " + data.name +
        "</h2><h2> Email : " + data.email +
        "</h2><p> Message : " + data.message +
        "</p></body><html>";
      mail_data["Subject"] = data.subject;
    }
    if (w == "esolutions") {
      msg = "<html><head></head><body>" +
        "<h2> Name : " + data.name +
        "</h2><h2> Email : " + data.email +
        "</h2><h2> Phone : " + data.phone +
        "</h2><p> Message : " + data.message +
        "</p></body><html>";
      mail_data["Subject"] = "E-Solutions Enquiry";
    }

    mail_data["Body"] = msg;

    console.log(mail_data)
    sendMail(mail_data, (d) => {
      console.log(d);
      if (d == "OK") {
        Swal.fire({
          title: 'Request Sent',
          text: 'Your request has been send successfully',
          icon: 'success',
          confirmButtonText: 'Done'
        });
      } else {
        Swal.fire({
          title: 'Request Sending failed',
          text: 'Your request sending failed try again later',
          icon: 'error',
          confirmButtonText: 'Done'
        })
      }
    });
  }
}
