<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/bn6.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4">Here at MediComp<span>we offer custom report</span></h1>
                    <h3 class="subheading">Here at MediComp we offer custom report designs for your needs</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2 mt-0">
            <div class="col-md-10 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Custom Report Design</span>
                <h2 class="mb-4">MediComp we offer custom report designs</h2>
                <p>Time-efficiency is probably one of the most important benefits of custom reporting/dashboards. Instead of gathering data, preparing and crunching the numbers in Excel, and then having to create the report, for example, in PowerPoint, you could use a tool that does all of that for you.</p>
            </div>
        </div>
        <div class="row text-center align-items-stretch">
            <div class="col-md-12">
                <div class="service-box">
                    <div class="service-icon" style="background-color: #2f89fc">
                        <div class="front-content">
                            <p style="color: #fafafa;">Here at MediComp we offer custom report designs for your needs, when the reports that come with your software just don’t offer you the information you are looking for.We offer custom report designs for the following programs Focus Reports, Medisoft, Altapoint.</p>
                           <p><strong>We offer custom report designs for the following programs:</strong></p>
                           
                                <p style="color: #fafafa;"> 1. Focus Reports <span style="padding: 15px;"> 2. Medisoft</span>   3. Altapoint </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>