<section class="home-slider owl-carousel">
    
    <!-- <div class="slider-item" style="background-image:url(assets/images/jesse-orrico-rmWtVQN5RzU-unsplash.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">
                        <img class="aligncenter wp-image-1687 size-medium" src="assets/images/eSolutions.png" alt=""
                            style="width: 300px; height: 44px;">
                            Electronic Claims
                    </h1>
                    <p style="text-align: justify;" style="color: #fff;font-weight: bold;">Count on smart revenue management solutions from eSolutions and
                        Medicomp.</p>
                    
                </div>
            </div>
        </div>
    </div> -->
    <div class="slider-item" style="background-image:url(assets/images/gahag-0046631705-1-800x485.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">
                        <img class="aligncenter wp-image-1687 size-medium" src="assets/images/eSolutions.png" alt=""
                            style="width: 300px; height: 44px;">
                            Electronic Claims
                        <!-- <span>Electronic Claims </span> -->
                    </h1>
                    <p style="text-align: justify;" style="font-weight: bold;">Count on smart revenue management solutions from eSolutions and
                        Medicomp.</p>
                    <!-- <p class="subheading"><b>With the latest Medisoft release, CGM offers Medisoft Clinical, a
                            complete electronic health record/practice management system that works seamlessly to
                            minimize
                            practice disruption.</b></p> -->
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/5-QUESTIONS-CLAIMS-SOFTWARE_IMAGE-1170x570.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">
                        <img class="aligncenter wp-image-1687 size-medium" src="assets/images/eSolutions.png" alt=""
                            style="width: 300px; height: 44px;">
                            Electronic Claims
                    </h1>
                    <p style="text-align: justify;" style="font-weight: bold;">Count on smart revenue management solutions from eSolutions and
                        Medicomp.</p>
                    <!-- <p class="subheading"><b>With the latest Medisoft release, CGM offers Medisoft Clinical, a
                            complete electronic health record/practice management system that works seamlessly to
                            minimize
                            practice disruption.</b></p> -->
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div class="justify-content-center pt-2 pt-4">
    <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
        <div class="img-frame center">
            <img class="aligncenter wp-image-1687 size-medium" src="assets/images/eSolutions.png" alt="" width="300"
                height="44" style="opacity: 1; visibility: visible;">
        </div>
        <h2 class="mb-4">Electronic Claims</h2>
        <p>Count on smart revenue management solutions from eSolutions and Medicomp.</p>
    </div>
</div> -->

<section class="ftco-section ftco-no-pt ftco-no-pb ftco-counter img" id="section-counter"
    style="background-image: url(assets/images/bg_3.jpg); padding-top: 15px;" data-stellar-background-ratio="0.5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 py-5 pr-md-5">
                <div class="heading-section heading-section-white ftco-animate mb-5">
                    <span class="subheading text-center">smart revenue management</span>
                    <h2 class="mb-2 text-center">eSolutions</h2>
                    <p class="justify-content: center;">Count on smart revenue management solutions from eSolutions and
                        Medicomp.</p>
                </div>
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="appointment-form ftco-animate">
                    <div class="d-md-flex">
                        <div class="form-group">
                            <input type="text" name="name" ngModel class="form-control" placeholder="Name">
                        </div>
                    </div>
                    <div class="d-md-flex">
                        <div class="form-group">
                            <input type="email" name="email" ngModel class="form-control" placeholder="E-mail:">
                        </div>
                    </div>
                    <div class="d-md-flex">
                        <div class="form-group">
                            <input type="Phone" class="form-control" placeholder="Phone Number">
                        </div>
                    </div>

                    <div class="d-md-flex">
                        <div class="form-group">
                            <textarea name="message" ngModel cols="30" rows="3" class="form-control"
                                placeholder="Message"></textarea>
                        </div>
                    </div>
                    <div *ngIf="errors.err" class="danger">
                        <p>{{errors.msg}}</p>
                      </div>
                    <div class="d-md-flex pt-2">
                        <input type="submit" value="submit" class="btn btn-secondary py-3 px-4 w-50">
                        <input type="submit" value="Reset" class="btn btn-secondary py-3 px-4 w-50 ml-4">
                    </div>
                </form>
            </div>
            <div class="col-lg-8 p-5 bg-counter aside-stretch">
                <h3 class="vr">Medicomp Solutions</h3>
                <p style="text-align: justify;">Successful billing and payment processes are vital to a healthy bottom
                    line.
                    Put the power of Medicomp and ClaimRemedi to work for your practice so you can collect what you’re
                    owed,
                    quickly and accurately. ClaimRemedi Clearinghouse can provide payer connections for professional,
                    institutional, dental and worker compensation claims.</p>
                <p style="text-align: justify;">Successful billing and payment processes are vital to a healthy bottom
                    line.
                    Put the power of Medicomp and ClaimRemedi to work for your practice so you can collect what you’re
                    owed,
                    quickly and accurately. ClaimRemedi Clearinghouse can provide payer connections for professional,
                    institutional, dental and worker compensation claims.
                    Our high-tech claim scrubbing service is the best in the industry and is included in the cost of the
                    solution, so you get unlimited use of our scrubbing technology. Payer rules, both documented and
                    undocumented, are stored in a comprehensive rules engine that is maintained and continually updated
                    by staff
                    knowledgeable in both billing and ANSI claim submission.</p>
                <p style="text-align: justify;">With real-time claim edits, this system helps you easily fix the errors
                    that
                    were uncovered during the claim scrubbing process, ultimately helping reduce payer denials. The
                    system
                    generates easy-to-understand error messages so you can address them.More than 1,000,000 reliable and
                    exhaustive integrated real-time claim edits include payer specific and comprehensive industry edits
                </p>
                <p style="text-align: justify;">Our sales staff can provide you with more information about any of our
                    products. Simply complete our online form or call <span><a href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></span> to speak with one of our
                    representatives.</p>
                <p>For more information, visit <a href="https://www.esolutionsinc.com/claimremedi-and-medicomp"> https://www.esolutionsinc.com/claimremedi-and-medicomp</a>
                </p>
            </div>
        </div>
    </div>
</section>