import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-dragon-medical',
  templateUrl: './dragon-medical.component.html',
  styleUrls: ['./dragon-medical.component.scss']
})
export class DragonMedicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
