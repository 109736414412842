<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/dragon1.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Dragon Naturally Speaking <span>Medical Edition v10</span></h1>
                    <!-- <p class="subheading"><b>Call 703.203.1460 to Reserve Your Space</b></p>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/dragon2.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Dragon Naturally Speaking <span>Medical Edition v10</span></h1>
                    <!-- <p class="subheading"><b>Call 703.203.1460 to Reserve Your Space</b></p>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-2 pb-2">
    <div class="container">
        <div class="ftco-departments">

            <div class="row">
                <div class="col-md-12 tab-wrap">
                    <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel"
                        aria-labelledby="day-1-tab">
                        <div class="row departments" style="background-color:rgb(250, 250, 250);">
                            <!-- <div class="col-lg-4 order-lg-last d-flex align-items-stretch"> -->
                            <!-- <div class="img d-flex align-self-stretch" style="background-image: url(images/dept-100.jpg);"></div> -->
                            <!-- </div> -->

                            <div class="col-lg-12">
                                <!-- <h5 class="pt-4">Dragon Naturally Speaking</h5>
                                <h5>Medical Edition v10</h5> -->
                                <div class="item">
                                    <div class="testimony-wrap d-flex">
                                        <div class="user-img mr-4"
                                            style="background-image: url(assets/images/person_222.jpg);">
                                        </div>
                                        <div class="text ml-2 bg-light">
                                            <span class="quote d-flex align-items-center justify-content-center">
                                            </span>
                                            <p class="mt-4">Dragon Medical is up to 99% accurate out-of-the-box, and
                                                includes medical
                                                vocabularies covering nearly 80 medical specialties and subspecialties.
                                                Using Dragon Medical 10, physicians dictate in real-time into their EMR
                                                in their own words – letting them instantly review, sign, and make their
                                                notes available for other clinicians.</p>
                                        </div>
                                    </div>
                                </div>
                                <hr>


                                <div class="row mt-2 p-4">
                                    <h5>Dragon Medical accelerates dictation, saving minutes per patient as physicians:
                                    </h5>
                                    <ul class="list-unstyled">
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Navigate quickly throughout their EMR
                                            using voice commands</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Use macros to re-use frequently dictated
                                            text</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Document “medical decision-making” in
                                            their own words into the EMR</li>
                                    </ul>
                                    <h5>Dragon Medical 10 offers a series of features not available in Dragon
                                        Professional or Preferred:</h5>

                                    <p><strong>More Accurate Than Ever.</strong><br>
                                        20% more accurate than Dragon NaturallySpeaking Medical 9—and over 38% more
                                        accurate than Dragon Professional 10 when used in clinical settings.</p>

                                    <p><strong>EMR Support.</strong><br>
                                        Navigate and dictate inside EMR software; History of Present Illness, Review of
                                        Systems, assessment and plan.</p>
                                    <p><strong>Medical Vocabularies.</strong><br>
                                        More specialties than ever! Covers nearly 80 specialties and subspecialties.
                                        Dictate with confidence using your specialty’s vocabulary.</p>
                                    <p><strong>Regional Accent Support.</strong><br>
                                        Accented speakers get higher out-of-the-box recognition with advanced adaptation
                                        techniques and accent-specific acoustic models.</p>
                                    <p><strong>Supports HIPAA Regulations.</strong><br>
                                        Protect patient confidentiality.</p>
                                    <p><strong>Hidden Mode.</strong><br>
                                        Dictate anywhere in the EMR, eliminating navigation between EMR screens and the
                                        notes window.</p>
                                    <p><strong>DragonTemplates with Voice Fields.</strong><br>
                                        Automate form filling applications by adding fields in dictation templates.</p>
                                    <p><strong>Medical Formatting Rules.</strong><br>
                                        Expands abbreviations and follows capitalization rules. “HPI” can appear as
                                        either “History of Present Illness”, “history of present illness”, or “HPI.”</p>
                                    <p style="width: 100%;"><strong>PowerMic™ Ready.</strong><br>
                                        Program buttons to run any function or user voice command. </p>
                                    <p><strong>My Commands.</strong><br>
                                        Personal voice commands appear in Command browser for faster use.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</section>