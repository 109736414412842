import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-elecronic-health-record',
  templateUrl: './elecronic-health-record.component.html',
  styleUrls: ['./elecronic-health-record.component.scss']
})
export class ElecronicHealthRecordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
