<section class="hero-wrap hero-wrap-2" style="background-image: url('assets/images/contact_us2.jpg');"
    data-stellar-background-ratio="0.5">
    <!-- <div class="overlay"></div> -->
    <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate text-center">
                <h1 class="mb-2 bread">Contact Us</h1>
                <p class="breadcrumbs float-left"><span class="mr-2"><a href="javascript:void(0);" [routerLink]="['/']">Home <i
                                class="ion-ios-arrow-forward"></i></a></span> <span>Contact <i
                            class="ion-ios-arrow-forward"></i></span></p>
            </div>
        </div>
    </div>
</section>

<section class="ftco-section ftco-no-pt ftco-no-pb contact-section">
    <div class="container">
        <div class="row d-flex align-items-stretch no-gutters">
            <div class="col-md-6 p-4 p-md-5 order-md-last bg-light">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <div class="form-group">
                        <input type="text" name="name" ngModel class="form-control" placeholder="Your Name">
                    </div>
                    <div class="form-group">
                        <input type="text" name="email" ngModel class="form-control" placeholder="Your Email">
                    </div>
                    <div class="form-group">
                        <input type="text" name="subject" ngModel class="form-control" placeholder="Subject">
                    </div>
                    <div class="form-group">
                        <textarea name="message" ngModel cols="30" rows="7" class="form-control"
                            placeholder="Message"></textarea>
                    </div>
                    <div *ngIf="errors.err" class="danger">
                        <p>{{errors.msg}}</p>
                    </div>
                    <div class="form-group">
                        <input type="submit" value="Send Message" class="btn btn-primary py-3 px-5">
                    </div>

                </form>
            </div>
            <div class="col-md-6 d-flex align-items-stretch">
                <div class="map" style="width: 100%;">
                    <iframe style="width: 100%;height: 584px;"
                        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=2750+Prosperity+Ave+%23110,+Fairfax,+VA+22031&amp;sll=38.876059,-77.237073&amp;sspn=0.039089,0.077162&amp;gl=us&amp;ie=UTF8&amp;hq=&amp;hnear=2750+Prosperity+Ave+%23110,+Fairfax,+Virginia+22031&amp;t=m&amp;z=14&amp;iwloc=A&amp;output=embed"
                        width="425" height="350" frameborder="0" marginwidth="0" marginheight="0"
                        scrolling="no"></iframe></div>
            </div>
        </div>
    </div>
</section>

<section class="ftco-section contact-section">
    <div class="container">
        <div class="row d-flex mb-5 contact-info">
            <div class="col-md-12 mb-4">
                <h2 class="h4">Contact Information</h2>
            </div>
            <div class="w-100"></div>
            <div class="col-md-3 d-flex">
                <div class="bg-light d-flex align-self-stretch box p-4">
                    <p><span>Address:</span> <b style="display: inline-block">Medicomp</b> 2750 Prosperity Ave. Suite
                        #110
                        Fairfax, VA 22031</p>
                </div>
            </div>
            <div class="col-md-3 d-flex">
                <div class="bg-light d-flex align-self-stretch box p-4">
                    <p><span>Phone:</span> <a href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></p>
                </div>
            </div>
            <div class="col-md-3 d-flex">
                <div class="bg-light d-flex align-self-stretch box p-4">
                    <p><span>Email:</span> <a href="mailto:{{gs.dataSet.email}}">{{gs.dataSet.email}}</a></p>
                </div>
            </div>
            <!-- <div class="col-md-3 d-flex">
                <div class="bg-light d-flex align-self-stretch box p-4">
                    <p><span>Website : </span> <a href="http://medicomp.org">www.medicomp.org</a></p>
                </div>
            </div> -->
        </div>
    </div>
</section>