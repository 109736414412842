import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-hardawer-networking',
  templateUrl: './hardawer-networking.component.html',
  styleUrls: ['./hardawer-networking.component.scss']
})
export class HardawerNetworkingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
