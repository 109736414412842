<section class="home-slider owl-carousel">
  <div class="slider-item" style="background-image:url(assets/images/img/501.jpg);">
      <div class="overlay"></div>
      <div class="container">
          <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
              <div class="col-md-6 text ftco-animate">
                  <h1 class="mb-4">provide quality <span>support our products</span></h1>
                  <h3 class="subheading">With over 20 years of experience we have the knowledge you need to solve any problems that may arise.</h3>
                  <p><a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
              </div>
          </div>
      </div>
  </div>
   <div class="slider-item" style="background-image:url(assets/images/img/502.jpg);">
      <div class="overlay"></div>
      <div class="container">
          <div class="row no-gutters slider-text align-items-center justify-content-start"
              data-scrollax-parent="true">
              <div class="col-md-6 text ftco-animate">
                 <h1 class="mb-4">provide quality <span>support our products</span></h1>
                  <h3 class="subheading">With over 20 years of experience we have the knowledge you need to solve any problems that may arise.</h3>
                  <p><a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
              </div>
          </div>
      </div>
  </div>
</section>

<div class="container">

  <div class="">
    <div class="col-md-12 col-sm-12 col-12">
      
      <div class="body">
        <div class="row">
          <div class="col-md-10 pl-4 pt-4">
            <p style="text-align: justify;">We provide quality technical support for all of our products. With over 20
              years of experience we have the knowledge you need to solve any problems that may arise. All support
              will be offered Onsite or Offsite. Our expert technicians can troubleshoot and solve any problems you
              may have.</p>


            <div class="row">
              <div class="col-md-6 pl-4">
                <h4>Software</h4>
                <ul class="list-unstyled">
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Software
                    Malfunction/Application Error</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2"
                      style="color:#2b8dcf;"></span>Installation/Uninstalling</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Updates</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Virus Removal</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2"
                      style="color:#2b8dcf;"></span>Spam/Spy-ware/Ad-ware Removal</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Security</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>E-mail Setup</li>
                </ul>
              </div>
              <div class="col-md-6 pl-4">
                <h4>Hardware</h4>
                <ul class="list-unstyled">
                  <li><span class="ion-ios-arrow-round-forward mr-2"
                      style="color:#2b8dcf;"></span>Installation/Uninstalling</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Repair/Restore</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Server/Workstation
                    Setup</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Networking &
                    Telephone Wiring</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Maintenance</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Hardware
                    Configuration</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Peripheral
                    Installation</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Hardware
                    Synchronization</li>
                </ul>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pl-4">
                <h4>Data</h4>
                <ul class="list-unstyled">
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Backup Solutions
                  </li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Data Corruption
                    Repair</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Data Conversion
                  </li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Database
                    Maintenance</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Database
                    Customization</li>
                  <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Data
                    Synchronization</li>
                </ul>
              </div>
              <div class="col-md-6 pl-4">
                <h4>Support for Old Versions of Medisoft</h4>
                <p style="text-align: justify;">We also offer support for older versions of Medisoft. If you are
                  having problems with ANY version of Medisoft, we can help you with your problems.</p>
              </div>
            </div>




          </div>


         
        </div>
      </div>
    </div>
  </div>
</div>