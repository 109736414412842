<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/img1.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Revolutionize your<span> practice with an intelligent EHR</span></h1>
                    <h3 class="subheading">Use an EHR that is designed to ensure a patient-centerd practice</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a>
                        <a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Request More Info</a></p>
                </div>
            </div>
        </div>
    </div>
</section>  
<section class="ftco-section">  
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Integrated EHR</span>
                <h2 class="mb-4">Revolutionize practice with an intelligent EHR</h2>
                <p>Use an EHR that is designed to ensure a patient-centerd practice</p>
            </div>
        </div>
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills d-flex text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="false" style="width: 33.33%;">Integrated EHR</a>
                        <a class="nav-link ftco-animate fadeInUp ftco-animated show" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" style="width: 33.33%;">Modern Medical Practice</a>

                        <a class="nav-link ftco-animate fadeInUp ftco-animated show" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false" style="width: 33.33%;">Comprehensive</a>
                    </div>
                </div>
                <div class="col-md-12 tab-wrap">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">

                        <div class="tab-pane py-2 fade active show" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-6 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/img/re-cycle-ehr.png);"></div>
                                </div>
                                <div class="col-lg-6">
                                    <h2>Integrated EHR</h2>
                                    <p style="text-align: justify;">Electronic health records (EHR) are the first step towards computerization of medical practice. Integrated care EHRs can record, store and transmit health information of individual patients in a secure form accessible by multiple authorized users.</p>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Remote Accessibility</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Round the clock Availability</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Speedy and user friendly</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Speech- to-text feature</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Customizable medical report generation</li>                         
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-day-2-tab">
                            <div class="row departments">
                                
                                <div class="col-lg-6">
                                    <h2>User friendly interface suitable for modern medical practice</h2>
                                    <p>An ideal EHR ensures confidentiality of patient information without compromising on accessibility.</p>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Smooth scheduling linked with EHR</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Easier crowd management at reception</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Consolidated patient chart management</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Greater connectivity with labs, pharmacy and consultants</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Customizable user-friendly screens for input with minimal typing</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Integrated reporting strategies for regulatory bodies and public health systems</li>                                 
                                        </ul>
                                    </div>                                    
                                </div>
                                <div class="col-lg-6 order-lg-last d-flex align-items-stretch">
                                    <div class="img" style="background-image: url(assets/images/img/progress-ehr.png);"></div>
                                </div>
                            </div>
                        </div>

                         <div class="tab-pane fade" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-day-3-tab">
                            <div class="row departments">
                                <div class="col-lg-6 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/img/features-ehr.png);"></div>
                                </div>
                                <div class="col-lg-5">
                                    <h2>Avoid these potential areas of conflict and ensure customer satisfaction</h2>
                                    <div class="row pt-2 ml-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Speedy</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Flexible</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>user-freindly</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Comprehensive</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Easily integrates</li>
                                            
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Key Features of EHR</span>
                 <!-- <h2 class="mb-4">Faster, easier and efficient Medical Billing</h2> -->
                <p>Nurses can record real-time patient care data using hand-held devices. Data entry is simplified to save time. Medical reports are generated in a time-stamped and comprehensive manner.</p>
            </div>
        </div>
        <div class="row text-center align-items-stretch">
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-plug"></i></div>
                            </div>
                            <h4 class="my-3">Accessible Data</h4>
                            <p class="text-center">Patient-centered records are easily accessible for authorized users to ensure security of the information stored. Records are updated on a real-time basis to ensure validity of the information. </p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow"style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-hand-rock-o"></i></div>
                            </div>
                            <h4 class="my-3">Real-time data</h4>
                            <p class="text-center">The software allows real-time entry of clinical and laboratory data. The system captures clinical examination data, available laboratory investigations and results on a real-time basis.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-file-text-o"></i></div>
                            </div>
                            <h4 class="my-3">Report Generation</h4>
                            <p class="text-center">Medical reports are generated in a time-stamped and comprehensive manner. Patients visits and procedural notes are displayed in a systematic manner. The software lets you analyze the data to deduce and track key growth</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6">
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-thumbs-o-up"></i></div>
                            </div>
                            <h4 class="my-3">Standardization of care</h4>
                            <p class="text-center">The system links the patient information to medical information databases and analyses the data to provide the user with information on relevant best-practice care</p>
                        </div>
                    </div>
                </div>           
        </div>
    </div>
</section>