import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc(): any;

@Component({
  selector: 'app-clime-statement',
  templateUrl: './clime-statement.component.html',
  styleUrls: ['./clime-statement.component.scss']
})
export class ClimeStatementComponent implements OnInit {

  constructor(public gs: GeneralService) { }
  errors = { err: false, msg: "" }

  ngOnInit(): void {
    initFunc();
  }
  onSubmit(f) {
    console.log(f.value);

    if (f.value.name == undefined || f.value.name == "") {
      this.errors.err = true
      this.errors.msg = "Name required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }

    if (f.value.email == undefined || f.value.email == "") {
      this.errors.err = true
      this.errors.msg = "Email required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.phone == undefined || f.value.phone == "") {
      this.errors.err = true
      this.errors.msg = "Phone Number required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }

    this.gs.sendEmail('esolutions', f.value);
  }

}
