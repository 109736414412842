import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContainerComponent } from './container/container.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { CleanicalEhrComponent } from './components/cleanical-ehr/cleanical-ehr.component';
import { PracManagementComponent } from './components/prac-management/prac-management.component';
import { ClimeStatementComponent } from './components/clime-statement/clime-statement.component';
import { CunsultingComponent } from './components/cunsulting/cunsulting.component';
import { TrainingComponent } from './components/training/training.component';
import { BillingSolComponent } from './components/billing-sol/billing-sol.component';
import { HardawerNetworkingComponent } from './components/hardawer-networking/hardawer-networking.component';
import { RemoteOnlineComponent } from './components/remote-online/remote-online.component';
import { CustumReportComponent } from './components/custum-report/custum-report.component';
import { SupportSolComponent } from './components/support-sol/support-sol.component';
import { DownloadLinkComponent } from './components/download-link/download-link.component';
import { EncoderProComponent } from './components/encoder-pro/encoder-pro.component';
import { V19DownloadComponent } from './components/v19-download/v19-download.component';
import { MobilePatientInketeComponent } from './components/mobile-patient-inkete/mobile-patient-inkete.component';
import { DragonMedicalComponent } from './components/dragon-medical/dragon-medical.component';
import { AppointMasterComponent } from './components/appoint-master/appoint-master.component';
import { AppointmentComponent } from './components/appointment/appointment.component';

import { SpecialityEHRComponent } from './components/speciality-ehr/speciality-ehr.component';
import { UniversitieSchoolsComponent } from './components/universitie-schools/universitie-schools.component';
import { ElecronicHealthRecordComponent } from './components/elecronic-health-record/elecronic-health-record.component';
import { PracticeManagementComponent } from './components/practice-management/practice-management.component';
import { NewFeatureComponent } from './components/new-feature/new-feature.component';

const routes: Routes = [
  {
    path: '', component: ContainerComponent, children: [
      { path: '', component: HomeComponent },
      { path: 'aboutus', component: AboutComponent },
      { path: 'contactus', component: ContactComponent },
      { path: 'testimonials', component: TestimonialsComponent },
      { path: 'cleanical-ehr', component: CleanicalEhrComponent },
      { path: 'prac-management', component: PracManagementComponent },
      { path: 'clime-statement', component: ClimeStatementComponent },
      { path: 'cunsulting', component: CunsultingComponent },
      { path: 'training', component: TrainingComponent },
      { path: 'billing-sol', component: BillingSolComponent },
      { path: 'hardawer-networking', component: HardawerNetworkingComponent },
      { path: 'remote-online', component: RemoteOnlineComponent },
      { path: 'custom-report', component: CustumReportComponent },
      { path: 'support-sol', component: SupportSolComponent },
      { path: 'download-link', component: DownloadLinkComponent },
      { path: 'encoder-pro', component: EncoderProComponent },
      { path: 'v19download-link', component: V19DownloadComponent },
      { path: 'mobile-patient-inkete', component: MobilePatientInketeComponent },
      { path: 'dragon-medical', component: DragonMedicalComponent },
      { path: 'appoint-master', component: AppointMasterComponent },
      { path: 'appointment', component: AppointmentComponent },   
         
      { path: 'elecronic-health-record', component: ElecronicHealthRecordComponent },
      { path: 'Practice-management', component: PracticeManagementComponent },
      { path: 'new-feature', component: NewFeatureComponent },
      { path: 'medical-billing', component: SpecialityEHRComponent },
      { path: 'revenue-cycle-management', component: UniversitieSchoolsComponent },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
