<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/bn2.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4" style="color: #ff8000;">Medicomp – Your<span>Complete Medical Billing Solution</span></h1>
                    <h3 class="subheading">Medical practices are challenged today evolving healthcare laws and regulations.</h3>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-2 pb-2">
            <div class="col-md-10 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Medical Billing Solution</span>
                <h2 class="mb-4">Medicomp – Your Complete Medical Billing Solution</h2>
                <p>Medical practices are challenged today more than ever to keep up with continually evolving healthcare laws and regulations. For this reason, it is crucial physicians turn to an experienced billing service, such as Medicomp, with the knowledge and expertise necessary to manage and navigate through the arduous process and politics involved with healthcare billing.</p>
            </div>
        </div>
        <div class="row text-center align-items-stretch pt-0">
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <h4 class="my-3">Clear Communication</h4>
                            <p class="text-center">We pride ourselves on our ability to provide quality customer service to both physicians and their patients. This is accomplished through effective, clear communication – beginning with how information is gathered from the patient, processed and then articulated back – thoughtfully and with patience.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <h4 class="my-3">Expertise</h4>
                            <p class="text-center">With over 20 years of experience in billing and claims, and a thorough knowledge and understanding of the insurance process, our billing team is ready to handle all of your billing needs.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <h4 class="my-3">Local, Reachable Service</h4>
                            <p class="text-center">Many of our clients have been with us for years – not only for our products, services and pricing, but because we offer local, personalized service to those physicians and medical practices within the Metro DC area. This means we are reachable – always there for you when you need us.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-6">
                <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                    <div class="card-body">
                        <h4 class="my-3">Dedication</h4>
                        <p class="text-center">The Medicomp Billing Team is highly motivated to process and collect payments in a timely manner and as a result we continue to have a high payment success rate. We are dedicated and committed to the health of your practice.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 p-4">
                <h4>What Billing Services do we offer?</h4>
                <ul class="list-unstyled">
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Filing
                        of all
                        patients’ claims (primary, secondary, workman’s comp or auto accident)</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Posting
                        of insurance
                        payments</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Claims
                        follow-up,
                        submissions and re-submissions</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Claim
                        rejections –
                        fixed and resubmitted</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Patient
                        remainder
                        balance billing</li>
                </ul>
                <h4>What is our reimbursement model?</h4>
                <p style="text-align: justify;">We receive a percentage of all payments received. This means
                    we do not
                    get paid until you get paid. The specific percentage depends on the size, longevity, and
                    income of
                    your practice. All billing services are included in this percentage except for any fees
                    associated
                    with a new client setup.</p>
                <h4>Do we provide reports to our clients?</h4>
                <p style="text-align: justify;">We provide monthly reports with a detailed summary on
                    payments and how
                    charges are calculated.</p>
            </div>
            <div class="col-md-6 p-4">
                <h4>Are we HIPAA compliant?</h4>
                <p style="text-align: justify;">We are HIPAA compliant and only partner with industry
                    leaders and
                    organizations such as CGM and eSolutions to ensure full compliance. All claims are
                    sent per current
                    CMS/Medicare rules and standards. Our team continues to stay abreast of all regulatory
                    changes that
                    affect compliance and reimbursement.</p>
                <h4>Why outsource your billing?</h4>
                <p style="text-align: justify;">The amount of time and resources trying to collect payments
                    definitely
                    add up. They include:</p>
                <ul class="list-unstyled">
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Technology costs</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Staff’s
                        labor and
                        benefit costs</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Opportunity costs due
                        to loss of practice space</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Office
                        supplies and
                        even postage can add up quickly</li>
                </ul>
            </div>
        </div>
    </div>
</section>