  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background: url(assets/images/banner.PNG) top center;background-repeat: no-repeat;background-size: cover;">
    <div class="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
      <div class="row">
        <div class="col-lg-8">
          <h1>NEW FEATURES -  <span>V29</span></h1>
          <h2 style="text-align: left;">CGM MEDISOFT </h2>

          <div class="btns" style="text-align: left;">
            <a [routerLink]="['/']" class="btn-menu animated fadeInUp scrollto">Home</a>
            <a [routerLink]="['/new-feature']" class="btn-book animated fadeInUp scrollto">New Feature</a>
          </div>
        </div>
        <div class="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
          
        </div>

      </div>
    </div>
  </section><!-- End Hero -->
  <main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
            <p>Save time moving charges and payments</p>
          </div>
        <div class="row">
          <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
            <div class="about-img">
                <div class="card-body" style="padding: 0.25rem;">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/1004634375" allowfullscreen></iframe>
                </div>
                
                </div>
            </div>

            
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <!-- <h2>Save time moving charges and payments</h2> -->
            
      
            <p>
                You asked. We listened. It is now possible to move charges and 
                associated patient payments between cases!
            </p>
            <p>Mistakes happen, but now it’s so much easier to rectify charges 
                    and associated patient payments posted to the wrong case. 
                    Now, when the patient forgets to mention that their insurance 
                    has changed, you’ll have a quick way to move the charges to 
                    the correct case.
            </p>
            <p>
                This fantastic feature takes away the frustration of error-prone 
                and time-consuming double-entries.
            </p>
            <ul>
              <li><i class="bi bi-check-circle"></i> <b>CGM MEDISOFT </b> is a trusted and affordable practice management 
                solution that simplifies the way you run your practice.</li>
              <li><i class="bi bi-check-circle"></i> With each release, <b>CGM MEDISOFT </b> gets better. More powerful. Every
                year, we add new layers of  functionality and usability.</li>
              <li><i class="bi bi-check-circle"></i> Discover some of the benefits of 
                upgrading to <b>CGM MEDISOFT </b> V29.</li>
            </ul>
           
          </div>
        </div>

      </div>
    </section><!-- End About Section -->
    <!-- ======= Specials Section ======= -->
    <section id="specials" class="specials">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>Easily merge patient charts</h4>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
         
          <div class="col-lg-11 mt-4 mt-lg-0">
            <div class="tab-content">
              <div class="tab-pane active show">
                <div class="row">
                  <div class="col-lg-8 details order-2 order-lg-1">
                    <p class="fst-italic">  Everyone has created unintended duplicate patients. Maybe it was a mistake. Maybe you didn’t know a patient had changed their name.</p>
                    <p> Before now, those records cluttered reports and introduced billing mistakes. With CGM MEDISOFT V29, you can easily merge one chart into another. Watch as all the data and transactions move into a single record.</p>
                    
                    <div class="section-title">
                        <h4>Do the right thing with pop-up patient alerts</h4>
                      </div>
                    <p> Every practice has protocols for how to handle patients with balances, missing information, cash-only payments, transportation needs, and more.</p>
                    <p>With CGM MEDISOFT V29, you can set up your own practice alerts and tag any patient with up to five notes or messages. You choose where and when the alerts pop up. Is it an alert for appointments, charges, or demographics?</p>
                    
                    
                    
                    
                    <div class="text-center order-1 order-lg-2">
                        <img src="assets/images/medisoft.PNG" alt="" class="img-fluid">
                    </div>


                    <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3>
                    <p>Best practices say to collect patient out-of-pocket amounts at the time of service.</p>
                    <p> CGM MEDISOFT V29 makes this even easier. See what’s owed with the enhanced display of up-to-date co-pay, co-insurance, and remaining deductible pulled directly from our eMEDIX* clearinghouse eligibility integration. This newly displayed information may be more current than what was scanned from the card on file. If the eligibility response co-pay is different than what was entered by your users, it is highlighted for your review.</p>
                    <p>Want to learn more about EDI with eMEDIX? Ask your representative for details, and start consolidating all your software and services with one trusted vendor.</p>
                
                    <img src="assets/images/co-pay.PNG" alt="" class="img-fluid">

                  </div>
                  <div class="col-lg-4 text-center order-1 order-lg-2">
                    <img src="assets/images/patientCharts.PNG" alt="" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
       </div>

    </section><!-- End Specials Section -->
   
    <!-- ======= Specials Section ======= -->
    <section id="specials" class="specials" style="text-align:justify !important;padding: 30px 0;">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h4>PDF file support</h4>
          </div>
  
          <div class="row" data-aos="fade-up" data-aos-delay="100">
           
            <div class="col-lg-11 mt-4 mt-lg-0">
              <div class="tab-content">
                <div class="tab-pane active show">
                  <div class="row">
                    <div class="col-lg-8 details order-2 order-lg-1">
                      <p class="fst-italic">  The user experience improves with <b>CGM MEDISOFT V29. </b> Previously, if you received a PDF document, you couldn’t store it or view it in the images tab. Now, you can import that document easily and view it directly from the multimedia tab.</p>
                     
                      <div class="section-title">
                          <h4>Easier practice selection</h4>
                        </div>
                      <p> If you’re a multi-tasker working across multiple client organizations with lots of instances of <b>CGM MEDISOFT </b>
                        running, you’re going to love this feature!</p>
                      <p>In <b>CGM MEDISOFT V29, </b> you can see the name of each 
                        practice by hovering over the icon on the Windows task bar. 
                        No more opening and closing to figure out which practice is 
                        which.</p>
                    </div>
                    <div class="col-lg-4 text-center order-1 order-lg-2">
                      <img src="assets/images/easierPractice.PNG" alt="" class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
         </div>
  
    </section><!-- End Specials Section -->
     <!-- ======= Specials Section ======= -->
     <section id="specials" class="specials" style="text-align:justify !important;padding: 20px 0;">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h4>Streamlined appointment reminders</h4>
          </div>
  
          <div class="row" data-aos="fade-up" data-aos-delay="100">
           
            <div class="col-lg-11 mt-4 mt-lg-0">
              <div class="tab-content">
                <div class="tab-pane active show">
                  <div class="row">
                    <div class="col-lg-8 details order-2 order-lg-1">
                      <p class="fst-italic">CGM CONNECTION* is our new, integrated reminders  option. </p>
                     
                      <p> With <b>CGM MEDISOFT V29, </b>  you can now choose default 
                        appointment reminder preferences for all patients at once.</p>
                      <p>It’s much easier to get a new patient set up. Previously, you had to individually opt each patient into certain contact preferences, but now you can set your standard and you’ll only need to modify specific patients as needed.</p>
                        <p>Another addition for multi-site practices allows you to tailor reminder messages with dynamic facility information. 
                            Simply assign a facility to an appointment using Office Hours.</p>
                    </div>
                    <div class="col-lg-4 text-center order-1 order-lg-2" style="text-align:justify;">
                        <div class="section-title" >
                            <h4>Upgrade to CGM MEDISOFT.</h4>
                          </div>
                          <p>Take advantage of these exciting new features, and see the host of other improvements we have 
                            made to better assist you in your revenue cycle operations.</p>
                            <p>Powerful solutions.</p>
                            <p>Hard working practices.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
         </div>
  
    </section><!-- End Specials Section -->
  </main><!-- End #main -->