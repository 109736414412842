<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/img3.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4" style="color: #ff8000;">We offer remote<span>online backup solutions</span></h1>
                    <h3 class="subheading">We offer remote online backup solutions at very competitive pricing.</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2 mt-0">
            <div class="col-md-10 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Remote Online Backup</span>
                <h2 class="mb-4">Remote online solutions at very competitive pricing</h2>
                <p>Time-efficiency is probably one of the most important benefits of custom reporting/dashboards. Instead of gathering data, preparing and crunching the numbers in Excel, and then having to create the report, for example, in PowerPoint, you could use a tool that does all of that for you.</p>
            </div>
        </div>
        <div class="row text-center align-items-stretch pt-0">
            <div class="col-md-12 col-sm-10">
                <div class="service-box">
                    <div class="service-icon" style="background-color: #f17f3a">
                        <div class="front-content">
                            <p style="color: #fafafa;">We offer remote online backup solutions at very competitive pricing. With remote backup solutions, your critical data will be backed up on our servers. This will give you the peace of mind that if anything drastic were to occur at your office, that your data is still safe.</p>
                            <p><strong>For more information, give us a call at <a href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a>.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>