import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc():any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public gs:GeneralService) { }
  errors = { err: false, msg: "" }

  ngOnInit(): void {
    initFunc();
  }
  onSubmit(f) {
    // f.value['date'] = document.getElementById('appointment_date').value;
    // f.value['time'] = document.getElementById('appointment_time').value
    console.log(f.value);
    if (f.value.name == undefined || f.value.name == "") {
      this.errors.err = true
      this.errors.msg = "Name required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.email == undefined || f.value.email == "") {
      this.errors.err = true
      this.errors.msg = "Email required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }
    if (f.value.subject == undefined || f.value.subject == "") {
      this.errors.err = true
      this.errors.msg = "Subject required";
      return;
    } else {
      this.errors.err = false
      this.errors.msg = "";
    }

    this.gs.sendEmail("contact",f.value);
  }

}
