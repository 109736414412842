import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc():any;

@Component({
  selector: 'app-encoder-pro',
  templateUrl: './encoder-pro.component.html',
  styleUrls: ['./encoder-pro.component.scss']
})
export class EncoderProComponent implements OnInit {

  constructor(public gs:GeneralService) { }

  ngOnInit(): void {
    initFunc();
  }

}
