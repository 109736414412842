<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/505.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4" style="color: #fafafa"> Install<span>Medisoft Version {{gs.dataSet.version}} Encoder Pro</span></h1>
                    <h3 class="subheading">Install Medisoft Version {{gs.dataSet.version}} Encoder Pro</h3>
                    <p><a href="#" class="btn px-4 py-3 mt-3" style="background: #2f89fc;">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
     <!-- <div class="slider-item" style="background-image:url(assets/images/img/506.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                  <h1 class="mb-4">Install<span>Medisoft Version {{gs.dataSet.version}} Encoder Pro</span></h1>
                    <h3 class="subheading">Install Medisoft Version {{gs.dataSet.version}} Encoder Pro</h3>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div> -->
</section>

<div class="container">
    <div class="">
        <div class="col-md-12 pt-4">
            <div class="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated"><span class="subheading">Medisoft Version {{gs.dataSet.version}}</span>
                <h2 class="mb-4">Medisoft Version {{gs.dataSet.version}} Encoder Pro</h2>
            </div>
            <p style="text-align: justify;">We provide quality technical support for all of our products. With over 20
                years of experience we have the knowledge you need to solve any problems that may arise. All support
                will be offered Onsite or Offsite. Our expert technicians can troubleshoot and solve any problems you
                may have.</p>
        </div>
        <div class="col-md-12 col-sm-12 col-12 mb-4">
            <!-- <img src="assets/images/medisoftbanner.gif" class="img-responsive" style="width: 100%;"> -->
            <div class="body">
                <div class="row" style="padding: 10px;">
                    <div class="col-md-8 col-sm-12 col-12 pl-4 ">
                        <h6 style="padding-bottom: 10px;">Medisoft Version {{gs.dataSet.version}}</h6>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Version {{gs.dataSet.version}}</h6>
                                            <p style="color: #fafafa;">Medisoft Patient Accounting Version {{gs.dataSet.version}} Web
                                                Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>
                                            <a href="https://drive.google.com/file/d/0B29XCtW3DiNvTjZwRHpPNnl5cGs/view?usp=sharing" class="w3-button w3-green">Download Medisoft V{{gs.dataSet.version}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-sm-12 col-12 pt-4 mb-4">
            <section class="faq">
                <div class="container">
                  <div class="faq-title">
                    <h2>VAR FAQ</h2>
                    <p>CGM MEDISOFT/CGM LYTEC Integration with CGM CONNECTION</p>
                  </div>
                  <ul class="faq-list">
                    <li data-aos="fade-up" data-aos-delay="100" class="aos-init aos-animate">
                      <a data-toggle="collapse" class="collapsed" href="#faq1" aria-expanded="false">What is CGM CONNECTION?  <i class="fas fa-arrow-up"></i></a>
                      <div id="faq1" class="collapse" data-parent=".faq-list">
                        <p>
                            CGM CONNECTION is an automated cost-effective patient messaging solution for CGM MEDISOFT and CGM LYTEC that gives customers the ability to set up personalized calls, text messages and/or emails to their patients to remind them of scheduled appointments.<br><br>CGM CONNECTION leverages an Internet connection to put the practice in touch with their patients, eliminating the need to purchase expensive equipment and telephone lines that they don't need or want to pay for. 
                        </p>
                      </div>
                    </li>
          
                    <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate">
                        <a data-toggle="collapse" href="#faq2" class="collapsed">How would this be of value to my customers?<i class="fas fa-arrow-up"></i></a>
                        <div id="faq2" class="collapse" data-parent=".faq-list">
                          <p>
                              Your customers can now spend their time more efficiently to help improve the way they deliver 
                              healthcare. If a medical practice isn’t reminding patients of appointments, they are certainly losing revenue with missed appointments. Even if they are calling patients, CGM CONNECTION can do it more cost effectively and send reminders beyond office hours.
                          </p>
                        </div>
                      </li>
          
                    <li data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">
                      <a data-toggle="collapse" href="#faq3" class="collapsed">Can I get more information on customization capabilities that the practice has? <i class="fas fa-arrow-up"></i></a>
                      <div id="faq3" class="collapse" data-parent=".faq-list">
                        <p>
                            The practice controls the advance notice for the reminder messages (example: email one week before the scheduled appointment, then voice call a reminder 3 days before appointment, and text the day before.) The practice may customize the various voice, text, and email scripts to fit their own situation.To keep text messages short, the practice may build in ‘short name’ abbreviations: appointment -> appt
                        </p>
                      </div>
                    </li>
          
                    <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate">
                      <a data-toggle="collapse" href="#faq4" class="collapsed">Are there basic scripts to model after, or does the customer start from nothing? <i class="fas fa-arrow-up"></i></a>
                      <div id="faq4" class="collapse" data-parent=".faq-list">
                        <p>
                            There is global repository of templates which can be copied to a customer and can be edited to fit their need. They don't need to start from scratch which will save time and effort.
                        </p>
                      </div>
                    </li>
          
                    <li data-aos="fade-up" data-aos-delay="500" class="aos-init aos-animate">
                      <a data-toggle="collapse" href="#faq5" class="collapsed">Will I have access in CGM CONNECTION to assist my customers? <i class="fas fa-arrow-up"></i></a>
                      <div id="faq5" class="collapse" data-parent=".faq-list">
                        <p>
                            As the Value-Added Reseller, you can be given access to all your customers in the Customer Portal. 
                            Through a dropdown selection, you may easily switch between customers so that you may readily assist 
                            your customer in setup and support of CGM CONNECTION.
                        </p>
                      </div>
                    </li>
          
                    <li data-aos="fade-up" data-aos-delay="600" class="aos-init aos-animate">
                      <a data-toggle="collapse" href="#faq6" class="collapsed">Do all providers in the practice have to use it? Is it the same cost regardless of how many use it? <i class="fas fa-arrow-up"></i></a>
                      <div id="faq6" class="collapse" data-parent=".faq-list">
                        <p>
                            As the Value-Added Reseller, you can be given access to all your customers in the Customer Portal. Through a dropdown selection, you may easily switch between customers so that you may readily assist your customer in setup and support of CGM CONNECTION.
                         </p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="600" class="aos-init aos-animate">
                        <a data-toggle="collapse" href="#faq7" class="collapsed">Are there any benefits when compared to other appointment reminder software? <i class="fas fa-arrow-up"></i></a>
                        <div id="faq7" class="collapse" data-parent=".faq-list">
                          <p>
                            Having the integration between CGM MEDISOFT, CGM LYTEC and CGM CONNECTION makes the utilization quick and easy since the data is transferring automatically, behind the scenes.<br>Having the solutions owned by the same company positions us to develop additional integration points more readily.
                           </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="600" class="aos-init aos-animate">
                        <a data-toggle="collapse" href="#faq8" class="collapsed">Will the practice be able to view the history of messages sent and confirmed?<i class="fas fa-arrow-up"></i></a>
                        <div id="faq8" class="collapse" data-parent=".faq-list">
                          <p>
                            CGM LYTEC can be set to display the confirmed and cancelled responses received through CGM CONNECTION, right on the schedule.<br>CGM MEDISOFT can produce a list of responses outside the calendar.<br><br>The CGM CONNECTION customer portal contains a detailed historical record of past communications and the status. This history is retained for a minimum of 30 days from the date of activity. This history record even contains a recording of any voice messages that were left.
                           </p>
                        </div>
                      </li>
                  </ul>
                </div>
              </section>
        </div>
    </div>
</div>