import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	constructor(public gs: GeneralService) {}
	osStyle: string = 'windows';
	link: string;
	ngOnInit(): void {
		if (navigator.userAgent.includes('Mac')) {
			this.link = '/assets/files/TeamViewerQS.zip';
		} else {
			this.link = '/assets/files/MedicompOnlineSupport.exe';
		}
	}
}
