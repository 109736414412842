<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/img9.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Be a leader<span>in your specialty</span></h1>
                    <h3 class="subheading">Combine your clinical expertise with the right technology to become the leader in your practice.</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 " style="border-radius: 0px;">Get Started</a>
                        <a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3 mt-3 ml-2" style="border-radius: 0px;">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Updated Electronic</span>
                <h2 class="mb-4">Updated Electronic solutions</h2>
                <p>Coordinate your clinical practice with confidence and clarity</p>
            </div>
        </div>
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills d-flex text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="false" style="width: 50%;">Medical Billing</a>
                        <a class="nav-link ftco-animate fadeInUp ftco-animated show" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" style="width: 50%;">Features Management</a>
                    </div>
                </div>
                <div class="col-md-12 tab-wrap">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade active show" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-5 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/img/re-cycle-pm1.png);"></div>
                                </div>
                                <div class="col-lg-7">
                                 
                                    
                                     <div class="text-center position-relative">
                                    <h2>Transforming care through practice management system</h2>
                                    <p style="text-align: justify;">Incorporate your EHR into your practice management system. Transform your medical office by simplifying your administrative and operational activities.</p>
                                    
                                </div>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Finance management</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Scheduling of Appointment</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Disease and practice code conversion (ICD-10)</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Insurance services that include eligibility verification and claims denial Management</li>
                                        </ul>
                                    </div>
                                    <p>Reduce clinical errors by having legitimate and pertinent information at the click of a button through a time-efficient.</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-day-2-tab">
                            <div class="container">
                                <div class="text-center position-relative">
                                    <h2>Key Features of Practice Management</h2>
                                    <hr class="title-divider">
                                </div>
                                <div class="row text-center align-items-stretch">
                                    <div class="my-4 col-md-4"><a href="/features/adaptability">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <div class="text-center my-4">
                                                        <div></div>
                                                    </div>
                                                    <h4 class="my-3">Adaptability</h4>
                                                    <p class="text-center">Tailor-made solutions that can accommodate a growing enterprise</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/state-of-the-art">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">State of the Art</h4>
                                                    <p class="text-center">Outdated software solutions with limited capabilities can hamper your progress with portability issues and by being expensive and time-consuming</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/standardized">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Standardized</h4>
                                                    <p class="text-center">Standardized protocols ensure portability of your patient data from one system to another</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/expandability">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Expandability</h4>
                                                    <p class="text-center">The system grows as your practice grows. Whether a large group practice or a small clinic expandability becomes a challenge with conventional practice management system. Overcome this issue by utilizing a system that grows with your practice</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/portability">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Portability </h4>
                                                    <p class="text-center">The system allows access to patient data through portable devices like cell phones and tablets</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/clinically-effective">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Clinically Effective</h4>
                                                    <p class="text-center">Clinicians can access a centralized database for various clinical decisions. Access medical databases and use their automated reference systems for your advantage</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/measurability">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Measurability</h4>
                                                    <p class="text-center">Clinicians can access a centralized database for various clinical decisions. Access medical databases and use their automated reference systems for your advantage</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/congruent-to-regulations">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Congruent to regulations</h4>
                                                    <p class="text-center">The quality, safety and efficiency of the EHR is ensured according to the Health Information Technology for Economic and Clinical Health (HITECH) Act</p>
                                                </div>
                                            </div>
                                        </a></div>
                                    <div class="my-4 col-md-4"><a href="/features/get-paid-faster">
                                            <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                                                <div class="card-body">
                                                    <h4 class="my-3">Get paid faster </h4>
                                                    <p class="text-center">Outsourced coding and billing to maximize payments.</p>
                                                </div>
                                            </div>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-12">
                                    <h4>Computerize your practice for an efficient and seamless workflow and for transforming the way you care for your patients</h4>                                    
                                    <div class="row pt-2 pl-4">
                                        <div class="col-md-4">
                                            <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Improve patient care</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Improve patient satisfaction</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Improve care outcomes</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Increase efficiency</li>                                            
                                        </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <ul class="list-unstyled">                      
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Coordinate workflow</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Accurate billing</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Save money, and time</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Transcription costs</li>
                                        </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Document storage and filling costs</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Insurance claim denials</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Automated coding</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Reduced errors</li> 
                                        </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-day-2-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(images/dept-2.jpg);"></div>
                                </div>
                                <div class="col-md-8">
                                    <h2>Surgical Deparments</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-first-aid-kit"></span></div>
                                                <div class="text">
                                                    <h3>Primary Care</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-dropper"></span></div>
                                                <div class="text">
                                                    <h3>Lab Test</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-experiment-results"></span></div>
                                                <div class="text">
                                                    <h3>Symptom Check</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-heart-rate"></span></div>
                                                <div class="text">
                                                    <h3>Heart Rate</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-day-3-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(images/dept-3.jpg);"></div>
                                </div>
                                <div class="col-md-8">
                                    <h2>Dental Deparments</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-first-aid-kit"></span></div>
                                                <div class="text">
                                                    <h3>Primary Care</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-dropper"></span></div>
                                                <div class="text">
                                                    <h3>Lab Test</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-experiment-results"></span></div>
                                                <div class="text">
                                                    <h3>Symptom Check</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-heart-rate"></span></div>
                                                <div class="text">
                                                    <h3>Heart Rate</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-day-4-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(images/dept-4.jpg);"></div>
                                </div>
                                <div class="col-md-8">
                                    <h2>Ophthalmology Deparments</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-first-aid-kit"></span></div>
                                                <div class="text">
                                                    <h3>Primary Care</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-dropper"></span></div>
                                                <div class="text">
                                                    <h3>Lab Test</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-experiment-results"></span></div>
                                                <div class="text">
                                                    <h3>Symptom Check</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-heart-rate"></span></div>
                                                <div class="text">
                                                    <h3>Heart Rate</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-day-5-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(images/dept-5.jpg);"></div>
                                </div>
                                <div class="col-md-8">
                                    <h2>Cardiology Deparments</h2>
                                    <p>On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-md-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-idea"></span></div>
                                                <div class="text">
                                                    <h3>Primary Care</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-idea"></span></div>
                                                <div class="text">
                                                    <h3>Lab Test</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-idea"></span></div>
                                                <div class="text">
                                                    <h3>Symptom Check</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="services-2 d-flex">
                                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span class="flaticon-idea"></span></div>
                                                <div class="text">
                                                    <h3>Heart Rate</h3>
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>