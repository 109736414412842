import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-support-sol',
  templateUrl: './support-sol.component.html',
  styleUrls: ['./support-sol.component.scss']
})
export class SupportSolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
