import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
declare function initFunc():any;

@Component({
  selector: 'app-v19-download',
  templateUrl: './v19-download.component.html',
  styleUrls: ['./v19-download.component.scss']
})
export class V19DownloadComponent implements OnInit {

  constructor(public gs:GeneralService) { }

  ngOnInit(): void {
    initFunc();
  }

}
