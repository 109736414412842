<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/img8.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4">Here at MediComp<span> your practice is our priority</span></h1>
                    <h3 class="subheading">You will have a more efficient practice, and we will provide the tools to streamline your work proces</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section ftco-no-pt ftc-no-pb">
    <div class="container">
        <div class="row no-gutters">
            <div class="row justify-content-center mb-5 pb-2 pt-4">
                <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                    <span class="subheading">Consulting</span>
                    <h2 class="mb-4">Here at MediComp</h2>
                    <p>You will have a more efficient practice, and we will provide the tools to streamline your work process, and increase the productivity of your office.</p>
                </div>
            </div>
            <div class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" style="background-image: url(assets/images/img/img7.jpg);">
            </div>
            <div class="col-md-7 wrap-about py-4 py-md-5 ftco-animate fadeInUp ftco-animated">
                <div class="heading-section mb-5">
                    <div class="pl-md-5 ml-md-5">
                        <span class="subheading">Consulting</span>
                        <h2 class="mb-2" style="font-size: 28px;">MediComp is the Washington DC Metropolitan area’s leading solution provider of Practice Management (PM) and Electronic Health Records (EHR) software</h2>
                    </div>
                </div>
                <div class="pl-md-5 ml-md-5 mb-5">
                    <p>Here at MediComp, your practice is our priority, therefore we carefully analyze the needs of your practice, and based on those needs, we supply you the best solution to get the most out of your practice.</p>
                    <p>You will have a more efficient practice, and we will provide the tools to streamline your work process, and increase the productivity of your office. This increase in productivity and efficiency will yield higher revenues for your practice, and maybe even just a little more time for yourself.</p>
                </div>
            </div>
        </div>
    </div>
</section>