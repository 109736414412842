<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/marek-levak-GNVxujZ_CxU-unsplash.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4"> CGM Medisoft Clinical EHR
                        <!-- <span>Medisoft Clinical EHR</span> -->
                    </h1>
                    <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical, a
                        complete electronic health record/practice management system that works seamlessly to minimize
                        practice disruption.</p>
                    <!-- <p class="subheading"><b>With the latest Medisoft release, CGM offers Medisoft Clinical, a
                            complete electronic health record/practice management system that works seamlessly to
                            minimize
                            practice disruption.</b></p> -->
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/e4ca39e5-e99b-4cf0-a334-8f9621ea6724.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <!-- <h1 class="mb-4">DEPARTMENTS <span>Medisoft Clinical EHR</span></h1> -->
                    <h1 class="mb-4"> Medisoft Clinical EHR
                        <!-- <span>Medisoft Clinical EHR</span> -->
                    </h1>
                    <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical, a
                        complete electronic health record/practice management system that works seamlessly to minimize
                        practice disruption.</p>
                    <!-- <p class="subheading"><b>With the latest Medisoft release, CGM offers Medisoft Clinical, a
                            complete electronic health record/practice management system that works seamlessly to
                            minimize
                            practice disruption.</b></p> -->
                    <!-- <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-2 pb-2">
    <div class="container">
        <!-- <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate">
                <span class="subheading">Departments</span>
                <h2 class="mb-4">Medisoft Clinical EHR</h2>
                <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical, a
                    complete electronic health record/practice management system that works seamlessly to minimize
                    practice disruption.</p>
            </div>
        </div> -->
        <div class="ftco-departments">
            <div class="row">

                <div class="col-md-12 tab-wrap">

                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate" id="v-pills-tabContent">

                        <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel"
                            aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch"
                                        style="background-image: url(assets/images/pmsolutions_green.png)"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>A Complete EHR/PM Solution</h2>
                                    <p style="text-align: justify;">With the latest Medisoft release, CGM offers
                                        Medisoft Clinical, a complete electronic health record/practice management
                                        system that works seamlessly to minimize practice sruption.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-first-aid-kit"></span></div>
                                                <div class="text">
                                                    <h3>Maximize Efficiency</h3>
                                                    <p style="text-align: justify;">Unlike other EHRs, Medisoft Clinical
                                                        features Bright Note Technology design that allows physicians to
                                                        complete the entire chart from a single progress note.</p>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <p class="mb-0"><a href="javascript:void(0)" class="mr-2">Read More..</a></p>
                                                        <!-- <p class="ml-auto mb-0">
                              <a href="#" class="mr-2">Read More..</a>
                              <a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a>
                            </p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-dropper"></span></div>
                                                <div class="text">
                                                    <h3>Enhance Quality</h3>
                                                    <p style="text-align: justify;">Web-based access to hundreds of
                                                        disease and medication protocols, A broad range of progress note
                                                        templates covering both primary care diagnosis and treatment.
                                                    </p>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <p class="mb-0"><a href="javascript:void(0)" class="mr-2">Read More..</a></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-experiment-results"></span></div>
                                                <div class="text">
                                                    <h3>Ensure Safety</h3>
                                                    <p style="text-align: justify;">Electronic prescribing (via
                                                        SureScripts) improves patient safety and speeds new prescription
                                                        and refill workflow.</p>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <p class="mb-0"><a href="javascript:void(0)" class="mr-2">Read More..</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-heart-rate"></span></div>
                                                <div class="text">
                                                    <h3>Physician Tools</h3>
                                                    <p style="text-align: justify;">Customize chart viewing by physician
                                                        to allow provider (and specialty) specific views for charts,
                                                        chart summaries and flow charts.</p>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <p class="mb-0"><a href="javascript:void(0)" class="mr-2">Read More..</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text">
                                            <span class="subheading">Departments</span>
                                            <p class="mb-4"><strong>Call us at <span><a href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></span> for more
                                                    information.</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>