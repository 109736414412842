import { Component, OnInit } from '@angular/core';
declare function initFunc():any;

@Component({
  selector: 'app-practice-management',
  templateUrl: './practice-management.component.html',
  styleUrls: ['./practice-management.component.scss']
})
export class PracticeManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFunc();
  }

}
