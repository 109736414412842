<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/bn11.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-7 text ftco-animate">
                    <h1 class="mb-4">Only training can<span>optimize efficiency</span></h1>
                    <h3 class="subheading">Only training can optimize your efficiency and introduce the many features of your software package.</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section ftco-no-pt ftc-no-pb">
    <div class="container">
        <div class="row no-gutters">
            <div class="row justify-content-center mb-5 pb-2 pt-4">
                <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                    <span class="subheading">Training</span>
                    <h2 class="mb-4">Only training can optimize your efficiency</h2>
                    <p>Only training can optimize your efficiency and introduce the many features of your software package. Starting with the right information can save hours of lost time and frustration.</p>
                </div>
            </div>
            <div class="col-md-7 wrap-about py-4 py-md-5 ftco-animate fadeInUp ftco-animated">
                <div class="heading-section mb-5">
                    <div class="pl-md-5">
                        <span class="subheading">Training</span>
                        <h2 class="mb-2" style="font-size: 28px;">Only training can optimize your efficiency and introduce the many features of your software package</h2>
                    </div>
                </div>
                <div class="pl-md-5 mb-5 pr-md-4">
                    <p>Only training can optimize your efficiency and introduce the many features of your software package. Starting with the right information can save hours of lost time and frustration. Our professional staff will tailor your training time to meet your exact needs. Training can be scheduled by the hour, on-site, off-site, or as a package. Whether you have just started your practice or you have been in business for a long time, you will benefit from the expert information that our team can provide.</p>
                    <p><strong>We offer the following training packages:</strong></p>
                    <div class="row pt-2">
                        <ul class="list-unstyled">
                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Onsite personal training with your staff</li>
                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Training is also available at our office</li>
                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Remote personal training (Broadband internet connection required)</li>
                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Learn at your own pace with our DVD training library bundled with training manuals(Not available for all products, contact us for availability)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" style="background-image: url(assets/images/dept-2.png);">
            </div>
        </div>
    </div>
</section>
<!-- <div class="web-second pt-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="left-web">
                    <i class="fa fa-angellist"></i>
                    <h4>Training</h4>
                    <p style="text-align: justify;">Only training can optimize your efficiency and introduce the many
                        features
                        of your software package. Starting with the right information can save hours of lost time and
                        frustration.
                        Our professional staff will tailor your training time to meet your exact needs. Training can be
                        scheduled
                        by the hour, on-site, off-site, or as a package. Whether you have just started your practice or
                        you have
                        been in business for a long time, you will benefit from the expert information that our team can
                        provide.
                    </p>
                    <p><strong>We offer the following training packages:</strong></p>
                    <ul class="list-unstyled">
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Onsite personal
                            training
                            with your staff</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Remote personal
                            training
                            (Broadband internet connection required)</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Learn at your
                            own pace
                            with our DVD training library bundled with training manuals(Not available for all products,
                            contact us
                            for availability)</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Training is
                            also
                            available at our office</li>
                    </ul>
                    <p>Please contact us for more information!</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                <div class="right-web"><img src="assets/images/dept-2.jpg" class="img-responsive" style="width: 100%;height: 500px; padding: 0px; margin-top: 0px;"></div>
            </div>
        </div>
    </div>
</div> -->